/* eslint-disable consistent-return */
import { produce } from 'immer';

const INITIAL_STATE = {
  processosProvaEtapa: 1,
  processosProva: [],
  processosProvaComentarios: [],
  loading: false,
  loadingAddProva: false,
  successAddProva: null,
};

export default function processosProva(state = INITIAL_STATE, action) {
  /* const printState = (draft) =>
    JSON.parse(JSON.stringify(draft)); */

  return produce(state, (draft) => {
    switch (action.type) {
      case '@processos_prova/SET_LOADING': {
        draft.loading = action.payload.loading;
        break;
      }
      case '@processos_prova/GET_PROCESSOS_PROVA_SUCCESS': {
        draft.processosProva = action.payload;
        break;
      }
      case '@processos_prova/GET_PROCESSOS_PROVA_ETAPA': {
        draft.processosProvaEtapa = action.payload;
        break;
      }
      case '@processos_prova/GET_PROCESSOS_PROVA_COMENTARIOS_SUCCESS': {
        draft.processosProvaComentarios = action.payload;
        break;
      }
      case '@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_AUDIO_REQUEST': {
        // eslint-disable-next-line prefer-destructuring
        break;
      }
      case '@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_SUCCESS': {
        // eslint-disable-next-line prefer-destructuring
        break;
      }
      case '@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_ERROR': {
        // eslint-disable-next-line prefer-destructuring
        break;
      }

      case '@processos_prova/ADD_PROVA_REQUEST': {
        draft.loadingAddProva = true;
        draft.successAddProva = null;
        break;
      }

      case '@processos_prova/ADD_PROVA_SUCCESS': {
        draft.loadingAddProva = false;
        draft.successAddProva = true;
        break;
      }

      case '@processos_prova/ADD_PROVA_ERROR': {
        draft.loadingAddProva = false;
        draft.successAddProva = false;
        break;
      }

      case '@processos_prova/CLEAR': {
        return { ...INITIAL_STATE };
      }

      default:
    }
  });
}
