import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as AulaActions from '../../store2/modules/aula/actions';

import { ButtonCard, Container } from './styles';

export default function CardCurso({
  funcao, curso, turmas, tipo,
}) {
  const dispatch = useDispatch();

  const handleSelect = (aula, nextPage) => {
    dispatch(AulaActions.selectAulaRequest(aula, nextPage));
  };

  const funcaoTela = useCallback(() => {
    switch (funcao) {
      case 'ocorrencias':
        return 'Ocorrencias';
      case 'faltas':
        return 'FrequenciaGeral';
      case 'relatorioFrequencia':
        return 'RelatorioFrequencia';
      case 'notas':
        return 'Notas';
      case 'processosProva':
        return 'processosProva';
      default:
        return '';
    }
  }, [funcao]);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Container.Header>
        <h4>{curso}</h4>
      </Container.Header>
      <Container.Body>
        {turmas.map((turma) =>
          (
            <div key={turma.CODTURMA}>
              <h4>{turma.NOME}</h4>

              <ul>
                {tipo === 'dia'
                  ? turma.AULAS.map((aula) =>
                    (
                      <li key={aula.IDHORARIOTURMA} className="">
                        <ButtonCard
                          type="button"
                          onClick={() =>
                            handleSelect(aula, 'FrequenciaDiaria')}
                        >
                          {aula.NOMEDISC}
                          {' '}
                          -
                          {' '}
                          {aula.HORAINICIAL}
                          {' '}
                          -
                          {' '}
                          {aula.HORAFINAL}
                        </ButtonCard>
                      </li>
                    ))
                  : turma.DISCIPLINAS.map((disciplina, index) =>
                    (index === 0
                    || disciplina.IDTURMADISC !== turma.DISCIPLINAS[index - 1].IDTURMADISC) && (
                      <li key={disciplina.IDTURMADISC}>
                        <ButtonCard
                          type="button"
                          onClick={() =>
                            handleSelect(disciplina, funcaoTela())}
                        >
                          {disciplina.NOMEDISCIPLINA}
                        </ButtonCard>
                      </li>
                    ))}
              </ul>
            </div>
          ))}
      </Container.Body>
    </Container>
  );
}
