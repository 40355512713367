import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as AulaActions from '../../store2/modules/aula/actions';

import { ButtonCard, Container } from './styles';

export default function CardCursoProcessoProva({
  curso, series,
}) {
  const dispatch = useDispatch();
  const [listSeries, setListSeries] = useState([]);

  useEffect(() => {
    const newSeries = series.map((serie) => {
      const newSerie = {
        NOMEHABILITACAO: serie.NOMEHABILITACAO,
        CODFILIAL: serie.CODFILIAL,
        DISCIPLINAS: serie.DISCIPLINAS,
        CODCURSO: serie.CODCURSO,
        CODHABILITACAO: serie.CODHABILITACAO,
      };
      if (serie.CODFILIAL === '2') {
        newSerie.NOMEHABILITACAO = `${serie.NOMEHABILITACAO} PA`;
      }
      return newSerie;
    });

    const resultSeries = newSeries.filter((value, index, self) =>
      !self.some((other, otherIndex) =>
        otherIndex < index
     && (other.CODHABILITACAO === value.CODHABILITACAO && other.CODFILIAL === value.CODFILIAL)));
    setListSeries(resultSeries);
  }, [series]);

  const handleSelect = (aula) => {
    dispatch(AulaActions.selectAulaRequest(aula, 'processosProva'));
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Container.Header>
        <h4>{curso}</h4>
      </Container.Header>
      <Container.Body>
        {listSeries.map((turma) =>
          (
            <div key={`${turma.CODCURSO}_${turma.CODHABILITACAO}_${turma.CODFILIAL}`}>
              <h4>{turma.NOMEHABILITACAO}</h4>

              <ul>
                {turma.DISCIPLINAS.map((disciplina, index) =>
                  (index === 0
                    || disciplina.IDTURMADISC !== turma.DISCIPLINAS[index - 1].IDTURMADISC) && (
                      <li key={disciplina.IDTURMADISC}>
                        <ButtonCard
                          type="button"
                          onClick={() =>
                            handleSelect(disciplina)}
                        >
                          {disciplina.NOMEDISCIPLINA}
                        </ButtonCard>
                      </li>
                  ))}
              </ul>
            </div>
          ))}
      </Container.Body>
    </Container>
  );
}
