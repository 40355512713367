import React, { useEffect } from 'react';
import {
  RootStateOrAny, useDispatch, useSelector,
} from 'react-redux';
import { Container, HeaderButtons, Titulo } from '../../styles/Defaults';
import Button from '../../components/Button';
import useNotas from '../../hooks/useNotas';
import HeaderProcessosProva from '../../components/HeaderProcessosProva';
import Accordion from './accordion';
import * as TurmaActions from '../../store2/modules/turma/actions';
import { IProcesso } from './types';

// import { Container } from './styles';

const ProcessosProva: React.FC = () => {
  const idTurmaDisc = useSelector((state: RootStateOrAny) =>
    state.aula.selected.IDTURMADISC);

  const processosProva = useSelector((state: RootStateOrAny) =>
    state.processosProva.processosProva);

  const instrumento = useSelector((state: RootStateOrAny) =>
    state.notas.instrumento);

  const [currentProcesso, setCurrentProcesso] = React.useState<IProcesso>();

  const dispatch = useDispatch();

  const { nomeDisc: titulo } = useNotas();

  useEffect(() => {
    setCurrentProcesso(processosProva.find((item) =>
      item.avaliacao === instrumento[0]?.DESCRICAO));
  }, [dispatch, instrumento, processosProva]);

  useEffect(() => {
    dispatch(TurmaActions.getTurmaDisciplinaRequest(idTurmaDisc));
    return () => {
      dispatch(TurmaActions.clearTurma());
    };
  }, [dispatch, idTurmaDisc]);

  return (
    <Container>
      <HeaderButtons>
        <Button to="/AulasGeral/processosProva">Voltar</Button>
        <Button to="/Home">Início</Button>
      </HeaderButtons>
      <Titulo>{titulo.replace(/[ABCD]+$/, '')}</Titulo>
      <HeaderProcessosProva idTurmaDisc={idTurmaDisc} />
      {
        processosProva.length > 0
        && instrumento.length > 0
        && currentProcesso
        && (
        <Accordion currentProcesso={currentProcesso} />
        )

      }
    </Container>
  );
};

export default ProcessosProva;
