import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import n8nAPI from '../../../services/n8n';
import {
  addProvaError,
  addProvaRequest,
  addProvaSuccess,
  getProcessosProvaComentariosRequest,
  getProcessosProvaComentariosSuccess,
  getProcessosProvaSuccess,
  setLoading,
} from './actions';

function* getProcessosProva({ payload }) {
  const {
    semestre, serie, coddisc, codfilial, avaliacao,
  } = payload;
  const response = yield call(n8nAPI.get, '/processos-prova', {
    params: {
      semestre,
      serie,
      coddisc,
      codfilial,
    },
  });
  if (response.status === 200 || response.status === 204) {
    if (response.data[0].id === undefined) {
      yield put(getProcessosProvaComentariosSuccess([]));
    } else {
      const { id } = response.data.find((item) =>
        item.avaliacao === avaliacao);
      yield put(getProcessosProvaComentariosRequest(id));
    }
    yield put(getProcessosProvaSuccess(response.data));
  }
}

function* getProcessosProvaComentarios({ payload }) {
  const { idProcessoProva } = payload;
  const response = yield call(n8nAPI.get, `/processos-prova/comentarios?id_processo=${idProcessoProva}`);

  if (response.status === 200 || response.status === 204) {
    if (Object.keys(response.data[0]).length > 0) {
      yield put(getProcessosProvaComentariosSuccess(response.data));
    }
  }
}

function* addProva({ payload }) {
  // ANO_SEMESTRE_SERIE_FILIAL_CODDISC_AVALIACAO
  const { buffer, fileName, idProcessoProva } = payload;
  const formData = new FormData();
  formData.append('file', buffer, fileName);
  formData.append('idProcessoProva', idProcessoProva);
  const response = yield call(n8nAPI.post, '/provas/upload', formData);
  if (response.status === 200 || response.status === 204) {
    toast.success('Prova enviada com sucesso!');
    yield put(addProvaSuccess());
  } else {
    toast.error('Ocorreu um erro ao enviar a prova!');
    yield put(addProvaError());
  }
}

function* addProcessosProvaComentariosAudio({ payload }) {
  try {
    yield put(setLoading(true));
    const { buffer, comment } = payload;
    const formData = new FormData();
    formData.append('file', buffer, crypto.randomUUID().concat('.webm'));
    formData.append('data', JSON.stringify(comment));
    const response = yield call(n8nAPI.post, '/processos-prova/comentarios/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200 || response.status === 204) {
      yield put(getProcessosProvaComentariosRequest(comment.id_processo, comment.id_etapa));
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* addProcessosProvaComentarios({ payload }) {
  const { comment } = payload;
  try {
    const response = yield call(n8nAPI.post, '/processos-prova/comentarios', comment);
    if (response.status === 200 || response.status === 204) {
      yield put(getProcessosProvaComentariosRequest(comment.id_processo, comment.id_etapa));
    }
  } catch (e) {
    toast.error('Etapa inválida, atualize a página!');
  }
}

export default all([
  takeLatest('@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_AUDIO_REQUEST', addProcessosProvaComentariosAudio),
  takeLatest('@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_REQUEST', addProcessosProvaComentarios),
  takeLatest('@processos_prova/GET_PROCESSOS_PROVA_REQUEST', getProcessosProva),
  takeLatest('@processos_prova/GET_PROCESSOS_PROVA_COMENTARIOS_REQUEST', getProcessosProvaComentarios),
  takeLatest('@processos_prova/ADD_PROVA_REQUEST', addProva),
]);
