import React, { useState, useEffect, useMemo } from 'react';

import CardCurso from '../../components/CardCurso';
import api from '../../services/api';
import MessageBox from '../../components/MessageBox';
import Button from '../../components/Button';
import AnimateContainer from '../../components/AnimateContainer';

import { Titulo, Container, SubTitulo } from '../../styles/SubjectScreen';
import CardCursoProcessoProva from '../../components/CardCurso/processo-prova';

function AulasGeral({ location }) {
  const [aulasGeral, setAulasGeral] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const funcao = useMemo(() => {
    const splitted = location.pathname.split('/');
    return splitted[splitted.length - 1];
  }, [location]);
  const funcaoNome = useMemo(() => {
    switch (funcao) {
      case 'faltas':
        return 'Frequências';
      case 'notas':
        return 'Notas';
      case 'relatorioFrequencia':
        return 'Relatório de frequências';
      case 'ocorrencias':
        return 'Ocorrências';
      case 'processosProva':
        return 'Processos Prova';
      default:
        return 'Função não encontrada!';
    }
  }, [funcao]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api.getAulasGeral(funcao);
      if (response.status === 200) {
        setAulasGeral(response);
      } else {
        setErrorMsg(response.mensagem);
        setAulasGeral(null);
      }
      setLoading(false);
    }
    fetchData();
  }, [funcao]);

  return (
    <AnimateContainer loading={loading}>
      <Container>
        <Button to="/Home" role="button">
          Voltar
        </Button>
        <Titulo>{funcaoNome}</Titulo>
        <SubTitulo>Selecione a disciplina abaixo:</SubTitulo>
        <Container.Cards>
          {(aulasGeral
            && Object.keys(aulasGeral.dados).map((curso) =>
              (
                funcao === 'processosProva' ? (
                  <CardCursoProcessoProva
                    curso={curso}
                    series={aulasGeral.dados[curso]}
                    key={curso}
                  />
                ) : (
                  <CardCurso
                    curso={curso}
                    turmas={aulasGeral.dados[curso]}
                    key={curso}
                    funcao={funcao}
                  />
                )
              ))) || <MessageBox type="primary" message={errorMsg} />}
        </Container.Cards>
      </Container>
    </AnimateContainer>
  );
}

export default AulasGeral;
