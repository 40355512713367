import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import AudioComponent from './audio-component';
import { IComments } from './types';
import Avatar from '../../components/Avatar';
import Container from '../../components/Avatar/styles';
import n8nAPI from '../../services/n8n';

interface IMessageProps {
  message: IComments
  lado?: string
  loading?: boolean
}

const MessageLoading = () =>
  (
    <div className="flex gap-12 animate-pulse px-5 items-center">
      <div className="rounded-full w-16 h-16 bg-slate-200" />
      <div className="h-24 bg-slate-200 rounded-2xl w-[37%]" />
    </div>
  );

const Message = ({ message, lado = 'esquerda', loading = false }: IMessageProps) => {
  const foto = useSelector((state: RootStateOrAny) =>
    state.login.user.picture);
  const [audio, setAudio] = useState('');

  useEffect(() => {
    if (message.audio && !message.audio.startsWith('blob')) {
      n8nAPI.get(`processos-prova/comentarios/download?fileName=${message.audio}`, { responseType: 'blob' }).then((response) => {
        const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
        setAudio(urlBlob);
      });
    }
  }, [message]);

  if (loading) {
    return <MessageLoading />;
  }

  return (
    <div className={`flex gap-12 items-center px-5 ${lado === 'direita' && 'flex-row-reverse'}`}>
      <div className="relative flex flex-col gap-3 text-center">
        {lado === 'esquerda'
          ? <Avatar src={foto} size="4" />
          : (
            <Container size="5">
              <div className="w-full flex justify-center items-center h-full text-3xl">{message.nome![0]}</div>
            </Container>
          )}
        {message.nome?.split(' ')[0]}
      </div>
      <>
        {message.audio
          ? (
            <div className="py-4 pr-6 pl-4 bg-[#f1f3f4] rounded-2xl flex max-[640px]:w-[80%]">
              <AudioComponent audioUrl={audio} />
            </div>
          )
          : (
            <div className="border-[1px] flex flex-col border-zinc-300 rounded-md p-4 tracking-wider text-xl ">
              {message.comentario}
            </div>
          )}
      </>
    </div>
  );
};

export default Message;
