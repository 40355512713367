import React from 'react';

const AudioComponent = ({ audioUrl }) =>
  (

    <audio controls src={audioUrl} preload="auto">
      <track kind="captions" />
    </audio>

  );

export default AudioComponent;
