import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';

import api from '../../../services/api';
import n8n from '../../../services/n8n';
import history from '../../../services/history';
import {
  loginSuccess, loginFailure, loginMaster,
} from './actions';

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

function* login({ payload }) {
  const { location } = payload;

  const params = new URLSearchParams(location.search.substring(1));
  let paramsJson = {};
  [...params.entries()].forEach((param) => {
    paramsJson = { ...paramsJson, [param[0]]: param[1] };
  });

  const response = yield call(api.checkUser, paramsJson);

  if (response.auth) {
    const {
      userId, picture, nome, parametros,
    } = parseJwt(
      response.access_token,
    );
    api.apiAxios.defaults.headers.Authorization = `Bearer ${response.access_token}`;
    n8n.defaults.headers.Authorization = `Bearer ${response.access_token}`;
    const isMaster = yield call(api.isMaster);
    yield put(
      loginSuccess(
        {
          userId,
          picture,
          nome,
          token: response.access_token,
        },
        parametros,
      ),
    );
    if (isMaster.data.master) {
      api.apiAxios.defaults.headers.Authorization = `Bearer ${isMaster.data.access_token}`;
      yield put(
        loginMaster(isMaster.data.dados),
      );
      history.push('/Master');
      return;
    }
    history.push('/Home');
  } else {
    yield put(loginFailure(response.message));
    history.push('/');
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  if (!payload.login.user) return;

  const { token } = payload.login.user;

  if (token) {
    api.apiAxios.defaults.headers.Authorization = `Bearer ${token}`;
    n8n.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

function* selectUserMaster({ payload }) {
  const { email } = payload;
  const response = yield call(api.setUserMaster, { email });
  const { access_token: accessToken } = response.data;
  if (accessToken) {
    const {
      userId, picture, nome, parametros,
    } = parseJwt(
      accessToken,
    );
    api.apiAxios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    n8n.defaults.headers.Authorization = `Bearer ${accessToken}`;
    yield put(
      loginSuccess(
        {
          userId,
          picture,
          nome,
          token: accessToken,
        },
        parametros,
      ),
    );
    history.push('/Home');
  }
}
export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@login/LOGIN_REQUEST', login),
  takeLatest('@login/SELECT_USER_MASTER', selectUserMaster),
]);
