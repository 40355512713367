export function getProcessosProvaRequest(semestre, serie, coddisc, codfilial, avaliacao) {
  return {
    type: '@processos_prova/GET_PROCESSOS_PROVA_REQUEST',
    payload: {
      semestre, serie, coddisc, codfilial, avaliacao,
    },
  };
}

export function getProcessosProvaComentariosRequest(idProcessoProva) {
  return {
    type: '@processos_prova/GET_PROCESSOS_PROVA_COMENTARIOS_REQUEST',
    payload: { idProcessoProva },
  };
}

export function getProcessosProvaComentariosSuccess(payload) {
  return {
    type: '@processos_prova/GET_PROCESSOS_PROVA_COMENTARIOS_SUCCESS',
    payload,
  };
}

export function getProcessosProvaSuccess(payload) {
  return {
    type: '@processos_prova/GET_PROCESSOS_PROVA_SUCCESS',
    payload,
  };
}
export function addProcessosProvaRequestAudio(buffer, comment) {
  return {
    type: '@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_AUDIO_REQUEST',
    payload: { buffer, comment },
  };
}

export function addProcessosProvaComentarioRequest(comment) {
  return {
    type: '@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_REQUEST',
    payload: { comment },
  };
}

export function addProvaRequest(buffer, fileName, idProcessoProva) {
  return {
    type: '@processos_prova/ADD_PROVA_REQUEST',
    payload: { buffer, fileName, idProcessoProva },
  };
}

export function addProvaSuccess() {
  return {
    type: '@processos_prova/ADD_PROVA_SUCCESS',
  };
}

export function addProvaError() {
  return {
    type: '@processos_prova/ADD_PROVA_ERROR',
  };
}

export function addProcessosProvaSuccess() {
  return {
    type: '@processos_prova/ADD_PROCESSOS_PROVA_COMENTARIOS_SUCCESS',
  };
}
export function clear() {
  return {
    type: '@processos_prova/CLEAR',
  };
}

export function setLoading(loading) {
  return {
    type: '@processos_prova/SET_LOADING',
    payload: { loading },
  };
}
