import React, {
  useEffect, useRef, useState,
} from 'react';
import { AudioRecorder, useAudioRecorder } from '@rafaelmarreca/react-audio-voice-recorder';
import * as AccordionRadix from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { MdSend, MdRefresh, MdCached } from 'react-icons/md';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AccordionProps, IComments } from './types';
import Message from './message';
import * as ProcessosProvaActions from '../../store2/modules/processos-prova/actions';
import Loading from '../../components/Loading';
import n8nAPI from '../../services/n8n';

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionRadix.AccordionTriggerProps>(
  ({ children, className, ...props }: AccordionRadix.AccordionTriggerProps, forwardedRef) =>
    (
      <AccordionRadix.Header className="flex">
        <AccordionRadix.Trigger
          className={classNames('flex justify-between items-center group data-[state=open]:border-b-[1px] border-solid border-[1px] border-b-0 w-full p-4 rounded text-left text-3xl', className)}
          {...props}
          ref={forwardedRef}
        >
          {children}
          <ChevronDownIcon
            className="transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180"
            aria-hidden
          />
        </AccordionRadix.Trigger>
      </AccordionRadix.Header>
    ),
);
AccordionTrigger.displayName = 'AccordionTrigger';

const AccordionContent = React.forwardRef<HTMLDivElement, AccordionRadix.AccordionContentProps>(
  ({ children, className, ...props }: AccordionRadix.AccordionContentProps, forwardedRef) =>
    (
      <AccordionRadix.AccordionContent
        className={classNames(
          'overflow-hidden data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown border-[1px] data-[state=open]:border-y-0 bg-zinc-50',
          className,
        )}
        {...props}
        ref={forwardedRef}
      >
        <div className="px-5 py-[15px]">{children}</div>
      </AccordionRadix.AccordionContent>
    ),
);
AccordionContent.displayName = 'AccordionContent';

const Accordion: React.FC<AccordionProps> = ({ currentProcesso }) => {
  const loading = useSelector((state: RootStateOrAny) =>
    state.processosProva.loading);
  const user = useSelector((state: RootStateOrAny) =>
    state.professor);
  const processosProvaComentarios = useSelector((state: RootStateOrAny) =>
    state.processosProva.processosProvaComentarios);
  const textoTarjetaRef = useRef<HTMLTextAreaElement>(null);
  const [listMessages, setListMessages] = useState(processosProvaComentarios);
  const { loadingAddProva } = useSelector((state: RootStateOrAny) =>
    state.processosProva);

  const { clearBlob, isRecording, ...recorderControls } = useAudioRecorder();

  const dispatch = useDispatch();

  const addAudioElement = async (blob: Blob, idEtapa: number) => {
    const audioUrl = URL.createObjectURL(blob);
    const comment: IComments = {
      audio: audioUrl,
      comentario: '',
      id: crypto.randomUUID(),
      id_etapa: idEtapa,
      id_processo: currentProcesso.id,
      id_user: user.codPessoa,
    };
    setListMessages((old) =>
      ([...old, { ...comment, loading: true, id_user: parseInt(user.codPessoa, 10) }]));

    dispatch(ProcessosProvaActions.addProcessosProvaRequestAudio(blob, comment));
  };

  const addComment = async (ref: React.RefObject<HTMLTextAreaElement>,
    idEtapa: number) => {
    const comentario = ref?.current?.value;
    if (!comentario) {
      return;
    }
    const comment: IComments = {
      audio: '',
      comentario,
      id: crypto.randomUUID(),
      id_etapa: idEtapa,
      id_processo: currentProcesso.id,
      id_user: user.codPessoa,
    };

    if (ref?.current) {
      // eslint-disable-next-line no-param-reassign
      ref.current.value = '';
    }
    dispatch(ProcessosProvaActions.addProcessosProvaComentarioRequest(comment));
  };

  const handleKeyDownTarjeta = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Impede a quebra de linha
      addComment(textoTarjetaRef, currentProcesso.etapa_atual);
    }
  };

  const handleFilesChange = (event) => {
    const file = event.target.files[0];
    // ANO_SEMESTRE_SERIE_FILIAL_CODDISC_AVALIACAO
    const {
      codperlet, semestre, serie, coddisc, codfilial, avaliacao,
    } = currentProcesso;
    const serieTratada = serie
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    const fileName = `${codperlet}_${semestre}_${serieTratada}_${codfilial}_${coddisc}_${avaliacao}.pdf`;
    if (file) {
      dispatch(ProcessosProvaActions.addProvaRequest(file, fileName, currentProcesso.id));
    }
  };

  const handleUpdateComments = () => {
    dispatch(
      ProcessosProvaActions
        .getProcessosProvaComentariosRequest(currentProcesso.id),
    );
  };

  const handleDownload = async (uri, name) => {
    const response = await n8nAPI.get(uri, { responseType: 'blob' });
    const link = document.createElement('a');
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    link.href = urlBlob;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(urlBlob);
  };

  useEffect(() => {
    if (isRecording) {
      setTimeout(
        () => {
          const canvas = document.getElementsByClassName('audio-recorder-visualizer')
            .item(0)?.getElementsByTagName('canvas');
          if (canvas?.length) {
            canvas[0].width = 500;
          }
        }, 200,
      );
    }
  }, [isRecording]);

  useEffect(() => {
    setListMessages(processosProvaComentarios);
  }, [processosProvaComentarios]);

  if (currentProcesso === undefined) {
    return (
      <Loading />
    );
  }

  return (
    <AccordionRadix.Root type="single" collapsible>
      {currentProcesso.etapa_atual >= 1
      && (
      <AccordionRadix.AccordionItem value="tarjeta">
        <AccordionTrigger>
          <div className="flex gap-5">
            Tarjeta
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="p-4">
            <a
              href={currentProcesso.link_tarefa}
              className="underline text-3xl"
              target="_blank"
              rel="noreferrer"
            >
              Link da Tarjeta
            </a>
          </div>
          <div className="w-full bg-white border-[1px] flex p-4 flex-col gap-10">
            {
              listMessages.length > 0 && listMessages.filter((item) =>
                item.id_etapa === 1).map((message) =>
                (
                  <Message
                    message={message}
                    key={message.id}
                    lado={message.id_user === parseInt(user.codPessoa, 10) ? 'esquerda' : 'direita'}
                    loading={message.loading}
                  />
                ))
            }
          </div>
          { currentProcesso.etapa_atual === 1 && (
          <div className="w-full px-4 py-3 border-[1px] border-t-0 flex gap-5 justify-between items-center bg-zinc-50">
            { !isRecording && (
            <textarea
              ref={textoTarjetaRef}
              className="w-full resize-y border-[1px] px-6 pt-3 text-2xl"
              onKeyDown={handleKeyDownTarjeta}
            />
            )}
            <div className={`${isRecording && 'w-full'}`}>
              <AudioRecorder
                classes={
                {
                  AudioRecorderClass: '!bg-primary audio-recorder-svg-color w-full',
                  AudioRecorderTimerClass: '!text-white',
                  AudioRecorderStatusClass: '!text-white w-full',
                }
                }
                onRecordingComplete={(blob) => {
                  addAudioElement(blob, 1);
                  clearBlob();
                }}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                recorderControls={{ clearBlob, isRecording, ...recorderControls }}
                downloadFileExtension="webm"
                showVisualizer
              />
            </div>
            { !isRecording && (
            <div className="flex gap-4">
              <button
                type="button"
                className="
                hover:bg-white hover:text-primary bg-primary
                text-white rounded-[100%] w-16 h-16 flex justify-center
                items-center transition-all hover:border-[1px] hover:border-primary"
                onClick={() =>
                  addComment(textoTarjetaRef, 1)}
              >
                {loading ? <MdCached className="w-8 h-8 animate-spin" /> : <MdSend className="w-8 h-8" />}
              </button>
              <button
                type="button"
                className="
                hover:bg-white hover:text-primary bg-primary
                text-white rounded-[100%] w-16 h-16 flex justify-center
                items-center transition-all hover:border-[1px] hover:border-primary"
                onClick={handleUpdateComments}
              >
                <MdRefresh className="w-10 h-10" />
              </button>
            </div>
            )}
          </div>
          )}
        </AccordionContent>
      </AccordionRadix.AccordionItem>
      )}
      { currentProcesso.etapa_atual >= 2
      && (
      <AccordionRadix.AccordionItem value="prova">
        <AccordionTrigger>Prova</AccordionTrigger>
        <AccordionContent>
          {
                currentProcesso.etapa_atual > 2 ? (
                  <div className="mb-6">
                    <button
                      type="button"
                      onClick={() =>
                        handleDownload(`provas/download?filename=${currentProcesso.link_prova}`, currentProcesso.link_prova)}
                      className="p-4 bg-primary text-white rounded-md text-2xl font-medium hover:bg-white hover:text-primary hover:border-[1px] border-primary transition-all"
                    >
                      Baixar prova
                    </button>
                  </div>
                ) : (
                  <div className="flex w-full gap-6 items-center">
                    <label
                      htmlFor="inputProva"
                      className={
              `border-[1px]
              text-2xl
            bg-white
            hover:bg-primary
            hover:text-white
              shadow-sm p-3 w-[20%]
              justify-center
              items-center
              flex mb-3 font-bold transition-all
              rounded-md cursor-pointer
              disabled:opacity-50 disabled:cursor-not-allowed
              `
}
                    >
                      {
                      loadingAddProva
                        ? <MdCached className="w-8 h-8 animate-spin" />
                        : (
                          <div>
                            Adicionar Prova
                            <input
                              id="inputProva"
                              type="file"
                              onChange={handleFilesChange}
                              accept="application/pdf"
                              className="hidden"
                              disabled={currentProcesso.etapa_atual !== 2 || loadingAddProva}
                            />
                          </div>
                        )
}
                    </label>
                    {currentProcesso.link_prova
                    && (
                    <button
                      type="button"
                      className="hover:underline text-lg"
                      onClick={() =>
                        handleDownload(`provas/download?filename=${currentProcesso.link_prova}`, currentProcesso.link_prova)}
                    >
                      Baixar prova
                    </button>
                    )}
                  </div>
                )
}
          <div className="w-full bg-white border-[1px] flex p-4 flex-col gap-10">
            {
              listMessages.length > 0 && listMessages.filter((item) =>
                item.id_etapa === 2).map((message) =>
                (
                  <Message
                    message={message}
                    key={message.id}
                    lado={message.id_user === parseInt(user.codPessoa, 10) ? 'esquerda' : 'direita'}
                    loading={message.loading}
                  />
                ))
            }
          </div>

          { currentProcesso.etapa_atual === 2 && (
          <div className="w-full px-4 py-3 border-[1px] border-t-0 flex gap-5 justify-between items-center bg-zinc-50">
            { !isRecording && (
            <textarea
              ref={textoTarjetaRef}
              className="w-full resize-y border-[1px] px-6 pt-3 text-2xl"
              onKeyDown={handleKeyDownTarjeta}
            />
            )}
            <div className={`${isRecording && 'w-full'}`}>
              <AudioRecorder
                classes={
                {
                  AudioRecorderClass: '!bg-primary audio-recorder-svg-color w-full',
                  AudioRecorderTimerClass: '!text-white',
                  AudioRecorderStatusClass: '!text-white w-full',
                }
                }
                onRecordingComplete={(blob) => {
                  addAudioElement(blob, 2);
                  clearBlob();
                }}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                recorderControls={{ clearBlob, isRecording, ...recorderControls }}
                downloadFileExtension="webm"
                showVisualizer
              />
            </div>
            { !isRecording && (
            <div className="flex gap-4">
              <button
                type="button"
                className="
                hover:bg-white hover:text-primary bg-primary
                text-white rounded-[100%] w-16 h-16 flex justify-center
                items-center transition-all hover:border-[1px] hover:border-primary"
                onClick={() =>
                  addComment(textoTarjetaRef, 2)}
              >
                {loading ? <MdCached className="w-8 h-8 animate-spin" /> : <MdSend className="w-8 h-8" />}
              </button>
              <button
                type="button"
                className="
                hover:bg-white hover:text-primary bg-primary
                text-white rounded-[100%] w-16 h-16 flex justify-center
                items-center transition-all hover:border-[1px] hover:border-primary"
                onClick={handleUpdateComments}
              >
                <MdRefresh className="w-10 h-10" />
              </button>
            </div>
            )}
          </div>
          )}
        </AccordionContent>
      </AccordionRadix.AccordionItem>
      )}
      { currentProcesso.etapa_atual >= 3
      && (
      <AccordionRadix.AccordionItem value="devolutiva">
        <AccordionTrigger className="border-b-[1px]">
          Retorno do coordenador após a aplicação
        </AccordionTrigger>
        <AccordionContent className="!border-b-[1px]">
          <div className="w-full bg-white border-[1px] flex p-4 flex-col gap-10">
            {
              listMessages.length > 0 && listMessages.filter((item) =>
                item.id_etapa === 3).map((message) =>
                (
                  <Message
                    message={message}
                    key={message.id}
                    lado={message.id_user === parseInt(user.codPessoa, 10) ? 'esquerda' : 'direita'}
                    loading={message.loading}
                  />
                ))
            }
          </div>
          { currentProcesso.etapa_atual === 3 && (
          <div className="w-full px-4 py-3 border-[1px] border-t-0 flex gap-5 justify-between items-center bg-zinc-50">
            { !isRecording && (
            <textarea
              ref={textoTarjetaRef}
              className="w-full resize-y border-[1px] px-6 pt-3 text-2xl"
              onKeyDown={handleKeyDownTarjeta}
            />
            )}
            <div className={`${isRecording && 'w-full'}`}>
              <AudioRecorder
                classes={
                {
                  AudioRecorderClass: '!bg-primary audio-recorder-svg-color w-full',
                  AudioRecorderTimerClass: '!text-white',
                  AudioRecorderStatusClass: '!text-white w-full',
                }
                }
                onRecordingComplete={(blob) => {
                  addAudioElement(blob, 3);
                  clearBlob();
                }}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                recorderControls={{ clearBlob, isRecording, ...recorderControls }}
                downloadFileExtension="webm"
                showVisualizer
              />
            </div>
            { !isRecording && (
            <div className="flex gap-4">
              <button
                type="button"
                className="
                hover:bg-white hover:text-primary bg-primary
                text-white rounded-[100%] w-16 h-16 flex justify-center
                items-center transition-all hover:border-[1px] hover:border-primary"
                onClick={() =>
                  addComment(textoTarjetaRef, 3)}
              >
                {loading ? <MdCached className="w-8 h-8 animate-spin" /> : <MdSend className="w-8 h-8" />}
              </button>
              <button
                type="button"
                className="
                hover:bg-white hover:text-primary bg-primary
                text-white rounded-[100%] w-16 h-16 flex justify-center
                items-center transition-all hover:border-[1px] hover:border-primary"
                onClick={handleUpdateComments}
              >
                <MdRefresh className="w-10 h-10" />
              </button>
            </div>
            )}
          </div>
          )}
        </AccordionContent>
      </AccordionRadix.AccordionItem>
      )}
    </AccordionRadix.Root>
  );
};

export default Accordion;
