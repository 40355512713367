import React from 'react';
import { ptBR } from 'date-fns/locale';
import format from 'date-fns/format';

import {
  faChartLine, faCheckSquare, faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RiFileEditFill } from 'react-icons/ri';
import { Button, CalendarIcon } from './styles';

export default function MenuButtons({ button }) {
  const today = new Date();

  const buttons = {
    frequenciaDiaria: (
      <>
        <Button to="/AulasDiaFrequencia" role="button">
          <Button.TodayWeek>
            {format(today, 'EEE', { locale: ptBR })}
          </Button.TodayWeek>
          <Button.TodayNumber>{format(today, 'dd')}</Button.TodayNumber>
        </Button>
        <Button.Text>Lançamento de frequência do dia</Button.Text>
      </>
    ),

    frequenciaGeral: (
      <>
        <Button to="/AulasGeral/faltas" role="button">
          <CalendarIcon>
            <thead>
              <tr>
                <th scope="col">D</th>
                <th scope="col">S</th>
                <th scope="col">T</th>
                <th scope="col">Q</th>
                <th scope="col">Q</th>
                <th scope="col">S</th>
                <th scope="col">S</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
              </tr>
              <tr>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>13</td>
                <td>14</td>
              </tr>
              <tr>
                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
                <td>20</td>
                <td>21</td>
              </tr>
              <tr>
                <td>22</td>
                <td>23</td>
                <td>24</td>
                <td>25</td>
                <td>26</td>
                <td>27</td>
                <td>28</td>
              </tr>
              <tr>
                <td>29</td>
                <td>30</td>
                <td>31</td>
              </tr>
            </tbody>
          </CalendarIcon>
        </Button>
        <Button.Text>Lançamento de frequência a posteriori</Button.Text>
      </>
    ),

    ocorrencias: (
      <>
        <Button to="/OcorrenciasGeral" role="button">
          <ul className="ocorrencias-icon">
            <li className="ocorrencias-icon_line">
              <hr />
            </li>
            <li className="ocorrencias-icon_line">
              <hr />
            </li>
            <li className="ocorrencias-icon_line">
              <hr />
            </li>
            <li className="ocorrencias-icon_line">
              <hr />
            </li>
            <li className="ocorrencias-icon_line">
              <hr />
            </li>
            <li className="ocorrencias-icon_line">
              <hr />
            </li>
          </ul>
        </Button>
        <Button.Text>Ocorrências</Button.Text>
      </>
    ),

    relatorios: (
      <>
        <Button to="/AulasGeral/relatorioFrequencia" role="button">
          <FontAwesomeIcon className="icon-button" icon={faChartLine} />
        </Button>
        <Button.Text>Relatórios</Button.Text>
      </>
    ),

    notas: (
      <>
        <Button to="/AulasGeral/notas" role="button">
          <FontAwesomeIcon className="icon-button" icon={faCheckSquare} />
        </Button>
        <Button.Text>Lançamento de notas</Button.Text>
      </>
    ),

    livros: (
      <>
        <Button to="/Livros" role="button">
          <FontAwesomeIcon className="icon-button" icon={faBookOpen} />
        </Button>
        <Button.Text>Livros</Button.Text>
      </>
    ),

    processosProva: (
      <>
        <Button to="/AulasGeral/processosProva" role="button">
          <RiFileEditFill className="icon-button" />
        </Button>
        <Button.Text>Processos Provas</Button.Text>
      </>
    ),
  };

  return buttons[button];
}
