import { all } from 'redux-saga/effects';

import login from './login/sagas';
import professor from './professor/sagas';
import aula from './aula/sagas';
import frequencia from './frequencia/sagas';
import aluno from './aluno/sagas';
import turma from './turma/sagas';
import notas from './notas/sagas';
import rubrica from './rubrica/sagas';
import livros from './livros/sagas';
import processosProva from './processos-prova/sagas';

export default function* rootSaga() {
  yield all([
    login, professor, aula, frequencia, aluno, turma, notas, rubrica, livros, processosProva]);
}
