import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import background from '../assets/img/new_bg.svg';
import NeutraDisplayLight from '../assets/fonts/neutradisp-light.otf';
import NeutraDisplayBold from '../assets/fonts/neutradisp-bold.otf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Neutra Display';
    src: url(${NeutraDisplayLight}) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Neutra Display';
    src: url(${NeutraDisplayBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
  }

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  html {
    scroll-behavior: smooth;
    /* overflow-y: scroll; */
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    color: #032b50;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: none;
    scroll-behavior: smooth;

   /*  @media screen and (min-width: 768px) {
      background: white url(${background}) no-repeat center top fixed;
      background-size: cover;
    } */
  }

  :root {
    --color-primary: #032b50;
    --color-white: #f0f0f0;
    --background-success: #00800029;
    --background-error: #f8d7da;
    --color-hover: #ccd4dc;
    --color-error: #721c24;
    --text-success: #1A5434;
    --border-success: #2a8754;
    font-size: 62.5%;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    @media screen and (max-height: 500px) and (orientation: landscape) {
      font-size: 45%;
    }

    @media screen and (max-width: 550px) {
      font-size: 50%;
    }

    @media screen and (min-width: 1440px) {
      font-size: 70%;
    }

    @media screen and (min-width: 2000px) {
      font-size: 100%;
    }
  }

  #root {
    /* width: 100vw; */
    height: 100%
    /* min-height: -webkit-fill-available; */
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  /* Modal Classes */
  .modal-opened {
    overflow: hidden;
  }
  /* End Modal Classes */

  /* Toastidy_toast-container */
  .Toastify__toast-container {
    font-size: 1.5rem;
  }

  /* End Toastidy_toast-container */

  .audio-recorder-svg-color > img {
    filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(1deg) brightness(111%) contrast(101%);
  }

  .audio-recorder.recording {
    width: 100%;
  }

  .audio-recorder-visualizer canvas {
    width: 100%;
    height: 30px;
  }

`;
